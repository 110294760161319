import axios from "axios";
import appConfig from "@/../config/config.json";
import { pathOf } from "@/composables/useRouteHelper";
import storage from "@/utils/storageUtil";

/**
 * Holds redirect types. Each key is the redirect type, and the value is an object with the name of the api call to retrieve the url for the redirect.
 * We also set url in the object to undefined for clarity - that will cache the url so we don't need to keep calling the api to get it.
 */
let redirects = {
  mongo: { url: undefined, api: "getMongoRedirectUrl" },
  parsingTester: { url: undefined, api: "getParsingTesterUrl" },
};

function callApi(path, data, o = {}) {
  const defaultOptions = { method: "GET", redirectType: "" };
  o = Object.assign({}, defaultOptions, o);

  return getApiUrl(o.redirectType).then((url) => {
    const fullPath = url + "/" + (o.redirectType === "parsingTester" ? "ParsingTester" : "ajax") + "/" + path;
    const headers = {};
    axios.defaults.withCredentials = true;

    let call = null;
    if (o.method === "GET") {
      const queryString = fullPath + (data ? "?" + new URLSearchParams(data).toString() : "");
      const limit = 2000;
      if (queryString.length >= limit) {
        // This is enforced by the security layer. Adding here so we catch it earlier.
        return Promise.reject("Query string too long in API request");
      }
      call = axios.get(queryString, { headers });
    } else if(o.method === "POST") {
      call = axios.post(fullPath, data, { headers });
    } else {
      //DM-2994 - added this check because the header was breaking PUT requests.
      //we need to look into this because these headers are generally valid for puts and may not be for delete
      //I think whether we set this header will depend on the data we're sending, not the method maybe should be an option to callApi
      //https://stackoverflow.com/questions/6306185/can-http-put-request-have-application-x-www-form-urlencoded-as-the-content-type#:~:text=POST%20supports%20application%2Fx%2Dwww,create%20or%20update%20a%20resource
      // if (o.method === 'POST') {
      //     headers['Content-Type'] = 'application/x-www-form-urlencoded';
      // }
      call = axios({ method: o.method, url: fullPath, data, headers });
    }

    return call.catch((e) => {
      if (e.response.status == 401) {
        storage.set("intendedRoute", location.href, true);
        location.href = pathOf("/login");
      }
      throw e;
    });
  });
}

function getApiUrl(redirectType) {
  if (!redirectType) {
    return Promise.resolve(appConfig.backend_url);
  }

  if (!(redirectType in redirects)) {
    return Promise.reject(`invalid redirect URL type ${redirectType}`);
  }

  // previously set - just return it
  if (redirects[redirectType].url) {
    return Promise.resolve(redirects[redirectType].url);
  }

  // if this is the first time using this redirect URL, get and set it now
  // TODO this nested recursive call works for now but probably a bad idea to leave this way
  return callApi(redirects[redirectType].api)
    .then((response) => (redirects[redirectType].url = response.data))
    .catch((err) => {
      console.error(`Error getting ${redirectType} redirect url`, err);
      return Promise.reject(err);
    });
}

// This shouldn't be necessary but see DM-2136
function removeDuplicatesFromTermRange(response) {
  const uniqueTids = {};
  const uniqueTerms = [];

  response.data.forEach((o) => {
    const key = o.term.tid + "|" + o.date;
    if (!uniqueTids[key]) {
      uniqueTids[key] = true;
      uniqueTerms.push(o);
    }
  });

  response.data = uniqueTerms;
  return response;
}

/**
 * Find the keys that contain the url and markup. The timeline JSON structure uses non-standard
 * key names (names depend on result type). Also, sometimes details are nested in an array.
 * Needed so far by markup and outlining. (This should really be done by the API)
 *
 * TODO move resultType fixes and iskp property here too
 */
function initTimelineForFindingResultDetails(response) {
  const isRegularDetailSection = (value) => typeof value === "object" && value.markup && value.url;
  const isContainerDetailSection = (value) =>
    Array.isArray(value) && isRegularDetailSection(value[0]);

  function getDetailSection(result) {
    let regular, container;
    for (const value of Object.values(result)) {
      if (isRegularDetailSection(value)) {
        regular = value;
        continue;
      }

      if (isContainerDetailSection(value)) {
        container = value;
        continue;
      }

      if (typeof value === "object" && value.image && isContainerDetailSection(value.image)) {
        container = value.image;
      }
    }

    // sometimes containers also have a regular detail section
    if (container || regular) {
      return container || regular;
    }
  }

  // TODO consider flattening the data into one level to make this simpler
  response.data.forEach((card) => {
    card.result = card.result.filter(isRelevantResult);
    // do this in a second step so sitelink logic below sees only the relevant results
    card.result = card.result.map((result, index) => {

      // handle featureresultspanel
      if (result.resulttype === 'featuredresultspanel') {
        processFeaturedResultspanel(result);
      }

      const detailSection = getDetailSection(result);
      result.computed = { detailSection, isContainer: Array.isArray(detailSection) };

      result.datetime = card.datetime;
      if (detailSection && result.computed.isContainer) {
        detailSection.forEach((section) => {
          section.pollid = card.pollid;
          section.datetime = card.datetime;
        });
      }

      if (result.resulttype === "sitelinkbox" && result.sitelink) {
        if (card.result[index - 1].standard) {
          result.siteLinkParent = card.result[index - 1].standard;
          card.result[index - 1].computed.hasSiteLinks = true;
        } else {
          // TODO maybe do this as part of isRelevantResult. Or better yet, all this should be in the API.
          console.warn("IMPACT: Found a sitelink without a parent");
          console.warn({ result });
          return null;
        }
      }

      return result;
    })
      .filter((result) => result);
  });

  return response;
}

function processFeaturedResultspanel(result) {
  const featuredResultTypes = [
    'featuredresultsdatacard',
    'featuredresultsimage',
    'featuredresultsvideo',
    'featuredresultswebsite',
    'featuredresultssocial',
    'featuredresultsgeneral'
  ];

  // Extract all elements from the arrays and store them in a new array
  const featuredResultsData = featuredResultTypes.reduce((acc, type) => {
    if (result.featuredresultspanel[type]) {
      acc.push(...result.featuredresultspanel[type]);
    }
    return acc;
  }, []);

  // Remove unnecessary attributes
  featuredResultTypes.forEach(type => {
    delete result.featuredresultspanel[type];
  });

  // Move 'css' and 'featuredresultsthumbnail' attributes to 'result' and remove them from 'featuredresultspanel'
  result.css = result.featuredresultspanel.css;
  delete result.featuredresultspanel.css;

  result.featuredresultsthumbnail = result.featuredresultspanel.featuredresultsthumbnail;
  delete result.featuredresultspanel.featuredresultsthumbnail;

  // Assign the new array to 'result.featuredresultspanel'
  result.featuredresultspanel = featuredResultsData;
}

function isRelevantResult(result) {
  return !(
    result.resulttype === "unrecognized" ||
    (result.resulttype === "standard" && result.standard?.desc === "Shopping ideas")
  );
}

// Remove unrecongnized elements that used to be included in cards and may have crept into iresults.
// We can delete this soon when all iresult sets don't have irrelevant results.
function cleanOldIresultSet(response) {
  response.data.Result = response.data.Result.filter(isRelevantResult);
  return response;
}

export default {
  deleteIresultSet: (id) => callApi("deleteIresultSet", { id }, { method: "PUT" }),

  deleteReportSetting: (report_setting_id) => callApi("deleteReportSetting", { report_setting_id }, { method: "PUT" }),

  dismissUserImpactNotification: (report_setting_id, user_subscription_id) => callApi("dismissUserImpactNotification", { report_setting_id, user_subscription_id }),

  elementReport: (type, pDiffid) => callApi("elementReport", { type, pDiffid }, { redirectType: "parsingTester" }),

  getCategoryTags: () => callApi("getCategoryTags"),

  getCtid: (clientid, tid) => callApi("getCtid", { clientid, tid }),

  getGPTRequest: (system, user, maxTokens, temperature, topP) => callApi("getGPTRequest", { system, user, maxTokens, temperature, topP }, { method: "POST" }),

  getIresultRecommendations: () => callApi("getIresultRecommendations"),

  getIresultSet: (clientid, id, ctid) => callApi("getIresultSet", { clientid, id, ctid }).then(cleanOldIresultSet),

  getIresultSets: (clientid, ctid) => callApi("getIresultSets", { clientid, ctid }),

  getKpHtml: (clientid, tid, date, page, color, modify) => callApi("serveHTML", { clientid, tid, date, page, color, modify }),

  getMarkup: (clientid, url, ts = null) => callApi("getMarkup", { clientid, url, ts: ts || Math.round(Date.now() / 1000) }),

  getMatchingClient: (clientid, filter, activeonly, filtertype) => callApi("getClients", { clientid, filter, activeonly, filtertype }),

  getMatchingClientTerm: (str) => callApi("getMatching", { str }),

  getMaxOwnedClients: () => callApi("getMaxOwnedClients"),

  getMyClients: (clientid) => callApi("getMyClients", { clientid }),

  getNotificationMediums: () => callApi("getNotificationMediums"),

  getPeerGroupAnalysis: (id, startdate, enddate) => callApi("getPeerGroupAnalysis", { id, startdate, enddate }),

  getPeerGroupByClientTerm: (clientid, tid) => callApi("getPeerGroupByClientTerm", { clientid, tid }),

  getPeerGroups: (clientid) => callApi("getPeerGroups", { clientid }),

  getReleaseLabel: () => process.env.NODE_ENV === "production" ? callApi("getReleaseLabel") : Promise.resolve({ data: "dev" }),

  getReportImageBase64: (width, height, url) => {
    // TODO handle this better
    if (process.env.NODE_ENV !== "production") { url = url.replace("localhost:8082", "localhost:4000"); }

    return callApi("getReportImageBase64", { width, height, url });
  },

  getReportTypes: () => callApi("getReportTypes"),

  // TODO: I think the paa_only param is always false
  postResults: (data) => callApi("getResults", data, { method: "POST" } ),

  getResultTypes: () => callApi("getResultTypes"),

  getSentimentReport: (userid, date) => callApi("getSentimentReport", { userid, date }),

  getSuggestions: (googlepollid, urlid, ctid, clientid) => callApi("getDetails", { googlepollid, urlid, ctid, clientid }),

  getTerm: (clientid, ctid) => callApi("getTerm", { clientid, ctid }),

  getTermDropDown: (clientid, ctid, resultset) => callApi("getTermDropDown", { clientid, ctid, resultset }),

  getTermRange: (clientid, ctid, start, end, resultset) => callApi("getTermRange", { clientid, ctid, start, end, resultset }).then(removeDuplicatesFromTermRange),

  getTermsLight: (clientid) => callApi("getTermsLight", { clientid }),

  getTermsSearch: (clientid, pattern) => callApi("getTermsSearch", { clientid, pattern }),

  getTermVersions: (clientid, term) => callApi("getTermVersions", { clientid, term }),

  getTimelineJson: (clientid, ctid, start, end, resultset, useMongo = false) => callApi("getTimelineJson", { clientid, ctid, start, end, resultset }, { redirectType: useMongo ? "mongo" : "" }).then(initTimelineForFindingResultDetails),

  getUserImpactNotification: (userid) => callApi("getUserImpactNotification", { userid }),

  getUserInfo: (clientid) => callApi("getUserInfo", { clientid }),

  getUserReports: (userid) => callApi("getUserReports", { userid }),

  getUserSubscriptions: () => callApi("getUserSubscriptions"),

  getFeaturedResultsScreenshot: (tid, date) => callApi("getFeaturedResultsScreenshot", { tid, date }),

  login: (email, password) => callApi("login", { email, password }),

  tokenlogin: (token) => callApi("tokenLogin", { token }),

  logout: () => callApi("logout"),

  mainReport: (pDiffid, page) => callApi("mainReport", { pDiffid, page }, { redirectType: "parsingTester" }),

  ownURL: (clientid, owned, urlid, possiblyAffectedUrlIds, ts, start, end, ctid, resultset) => callApi("ownURL", { clientid, owned, urlid, ts, urlids: possiblyAffectedUrlIds, start, end, ctid, resultset, }),

  parsedTermResults: (clientid, termid, branch, date) => callApi("parsedTermResults", { clientid, termid, branch, date }, { redirectType: "parsingTester" }),

  pDiffGet: () => callApi("pDiff", { redirectType: "parsingTester" }),

  pDiffPost: (termsetid, termsettype, reference, target, targetdate) => callApi("pDiff", { termsetid, termsettype, reference, target, targetdate }, { method: "POST", redirectType: "parsingTester" }),

  rateURL: (clientid, score, urlid, ts, action, start, end, ctid, resultset) => callApi("rateURL", { clientid, score, urlid, ts, action, allowOverwriteHistory: true, skipOwnershipCheck: true, start, end, ctid, resultset, }),

  renameIresultSet: (irid, name) => callApi("renameIresultSet", { irid, name }),

  reparse: (googlepollid, clientid) => callApi("reparse", { googlepollid, clientid, asJson: true }).then(initTimelineForFindingResultDetails),

  resetPassword: (code, password) => callApi("resetPassword", { code, password }),

  saveIresult: (id, results, siteLinks, kg) => callApi("saveIresult", { id, results, siteLinks, kg }, { method: "PUT" }),

  saveIresultSet: (name, tid, clientId, results, siteLinks, kg) => callApi("saveIresultSet", { name, tid, clientId, results, siteLinks, kg }, { method: "PUT" }),

  sendPasswordResetEmail: (email) => callApi("sendPasswordResetEmail", { email, isApp: true }),

  setAuthority: (clientid, auth, urlid, start, end, ctid, resultset) => callApi("setAuthority", { clientid, auth, urlid, start, end, ctid, resultset }),

  subscribeUserForReportSetting: (report_setting_id, email) => callApi("subscribeUserForReportSetting", { report_setting_id, email }),

  unsubscribeUserForReportSetting: (report_setting_id, email) => callApi("unsubscribeUserForReportSetting", { report_setting_id, email }),

  upsertReportSetting: (loggedInUser, reportSettingId, selectedReportType, selectedMediums, keywordSelections) => callApi("upsertReportSetting", { loggedInUser, reportSettingId, selectedReportType, selectedMediums, keywordSelections }, { method: "PUT" }),
};
