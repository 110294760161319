<template>
  <div class="cardheader" :class="zoomSelection">
    <div v-if="zoomSelection === 'small-view'">

      <div class="card" :title="headerText" :class="{ history: isViewHistory }"> {{ headerText }} </div>

    </div>

    <div v-else class="card" :class="{ 'indicate-copied': indicateCopied }" :data-tid="headerData.tid"
         :data-device="headerData.device" :data-pollid="headerData.pollid"
         :data-display-date="dateTime(headerData.datetime)">
      <div class="card-body w-100 d-flex justify-content-between">
        <div class="d-flex flex-column w-100">
          <div class="d-flex header-data justify-content-between">
            <div class="d-flex">
              <span class="align-self-center activate-reds-and-greens">
                <!--reds and greens class is for the markup plugin to load when the popup opens-->
                <span class="icons fs-4 align-middle activate-reds-and-greens" :class="pageIconClass"
                      :title="isPageIconClickable ? 'Show Screenshot' : ''" @click="pageIconClickHandler" />
              </span>
              <div class="d-flex flex-column ms-2 text-left align-self-center">
                <span class="title-date">{{
                  iresults ? "iResults" : displayDate(headerData.datetime)
                }}</span>

                <div class="dropdown" v-if="iresults && iresultSets">
                  <span class="dropdown-toggle" id="iresultListDropdown" data-bs-toggle="dropdown"
                        aria-expanded="false">{{
                          iresultName }}</span>
                  <ul class="dropdown-menu" aria-labelledby="iresultListDropdown">
                    <li v-for="(iresult, index) in iresultSets" :key="'iresult_option' + index">
                      <a class="dropdown-item" @click="$emit('handleIResultSelect', iresult.id)">{{ iresult.name }}
                      </a>
                    </li>
                  </ul>
                </div>

                <span class="title-location ellipsis" v-else>{{ headerLocation }}</span>
              </div>
            </div>
            <div class="reparse" v-if="parsing">
              <!--WIP - see if we can implement bootstrap spinner instead of the svg with css animation-->
              <i class="icons reparse-spin"></i> 
            </div>
            <i v-if="moveCardMode && !isViewHistory && isPrimaryPage"
               class="move-handle pi pi-arrow-right-arrow-left"></i>
            <div v-else>
              <div class="dropdown card-header-dropdown" v-if="
                !parsing &&
                ['timeline', 'iresults', 'parsing-tester'].includes(view) &&
                !['kp-card', 'screenshot'].includes(cardType)
              ">
                <button
                        class="dropdown-toggle p-button-outlined p-button-sm custom-dropdown-toggle clickable"
                        type="button" aria-haspopup="true" aria-expanded="false" @click="$refs.menu.toggle()">
                  <i class="pi pi-bars"></i>
                </button>
                <mini-popup ref="menu" class="profile client-search">
                  <a v-for="item in cardTypeModel" :key="item.label" class="dropdown-item"
                     :href="item.command ? '#' : 'javascript:void(0)'" @click="pickMenuItem(item)"
                     >
                    <div class="d-flex">
                      <i :class="item.icon"></i> <span class="ms-2">{{ item.label }}</span>
                    </div>
                  </a>
                </mini-popup>
              </div>
            </div>
          </div>
          <div>
            <div class="term-field d-flex align-items-center">
              <img :src="logoSrc"
                   :title="isLiveSearchAvailable ? 'Live Search' : ''"
                   :class="[logoClass, { clickable: isLiveSearchAvailable }]"
                   @click="liveSearchHandler()" />
              <span class="term-field-title ellipsis"
                    :class="{ unset: caseSensitive, 'clickable-text': enableSuggestions }"
                    @click="$refs.suggestions && $refs.suggestions.toggle()" :title="headerData.keyword">
                {{ headerData.keyword }}
              </span>

            </div>
            <mini-popup v-if="enableSuggestions" ref="suggestions" class="suggestion-wrapper">
              <span v-html="suggestions" />
            </mini-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IMPACT_QUERY_DATE_FORMAT,
  LONG_DATE_FORMAT,
  MEDIUM_DATE_FORMAT,
  SHORT_DATE_FORMAT,
} from "@/constants";
import { mapActions, mapGetters } from "vuex";

import useLiveSearchHelper from "@/composables/useLiveSearchHelper";
import { _ } from "vue-underscore";
import MiniPopup from '../modals/MiniPopup.vue';

export default {
  name: "CardHeader",
  emits: ["refreshCard", "handleIResultSelect", "pageIconSelect"],
  props: {
    headerData: Object,
    suggestions: String,
    isHide: Boolean,
    enableSuggestions: Boolean,
    iresults: Boolean,
    iresultSets: Array,
    iresultName: String,
    pageNumber: Number,
    cardType: String,
  },
  data() {
    return {
      parsing: false,
      refreshOverride: false,
      indicateCopied: false,
      dropdownId: 0,
      hotKeyPressed: false,
    };
  },
  components: {
    MiniPopup,
  },
  computed: {
    ...mapGetters("routeParams", {
      client: "client",
      view: "view",
      userInfo: "userInfo",
      resultset: "resultset",
      isViewHistory: "isViewHistory",
    }),
    ...mapGetters("toolbar", {
      zoomSelection: "zoomSelection",
      caseSensitive: "caseSensitive",
      selectedPages: "selectedPages",
    }),
    ...mapGetters("timeline", { moveCardMode: "moveCardMode" }),
    htmlLink() {
      const date = new Date(this.headerData.datetime) / 1000;
      const params = new URLSearchParams({
        clientid: this.client.clientid,
        tid: this.headerData.tid,
        date,
        page: this.pageNumber,
        color: 1,
      });
      return "/ajax/serveHTML?" + params.toString();
    },
    headerText() {
      switch (this.resultset) {
        case 'geo':
          return this.headerLocation;
        case 'peergroup':
          return this.headerData.keyword;
        default:
          return this.displayDate(this.headerData.datetime);
      }
    },
    headerLocation() {
      return (
        this.headerData.location +
        (this.headerData.lang && this.headerData.lang !== "en"
          ? " (" + this.headerData.lang + ")"
          : "")
      );
    },
    pageIconClass() {
      const mobile = this.headerData.device === "mobile" ? "-mobile" : "";
      const page = this.pageNumber === 1 ? "one" : "two";
      return [`page-${page}${mobile}`, { clickable: this.isPageIconClickable }];
    },
    isPageIconClickable() {
      return !this.cardType;
    },
    isPrimaryPage() {
      return this.selectedPages[0] === this.pageNumber;
    },
    cardTypeModel() {
      let type = this.speedDialItems;
      switch (this.cardType) {
        case "i-results":
          type = this.speedDialIresultItems;
          break;
        case "parsing-tester":
          type = this.speedDialParsingTesterItems;
          break;
      }
      type = type.filter(item => !item.disabled);
      if (this.hotKeyPressed === true) {
        const hotKeyReparse = this.speedDialItems.find(item => item.label === 'Reparse');
        if (hotKeyReparse) {
          type.push(hotKeyReparse);
        }
      }
        return this.userInfo.is_su ? type : type.filter((item) => !item.suOnly);
    },
    isLiveSearchAvailable() {
      return this.headerData.device === "pc" && this.headerData.engine === "google";
    },
    speedDialParsingTesterItems() {
      return [
        {
          label: "Screenshot",
          icon: "pi pi-external-link",
          command: () => window.open(this.htmlLink, "_blank"),
        },
      ];
    },
    // in computed instead of data because they reference computed values
    speedDialItems() {
      return [
        {
          label: "Screenshot",
          icon: "pi pi-external-link",
          command: () => window.open(this.htmlLink, "_blank"),
        },
        {
          label: "Hide",
          icon: "pi pi-times",
          command: () => this.toggleHideCardById(this.headerData.pollid),
          disabled: !["timeline"].includes(this.view),
        },
        {
          label: "History",
          icon: "bi bi-clock-history",
          command: () => this.setActionGetHistory(this.headerData.tid),
        },
        {
          label: "Copy",
          icon: "bi bi-file-earmark",
          command: () => this.$parent.$emit("showCopyCardOptions"),
          disabled: !this.isViewHistory || this.isCopied || !["timeline"].includes(this.view),
        },
        {
          label: "Reparse",
          icon: "pi pi-refresh",
          command: () => this.initReparse(this.headerData.pollid, this.client.clientid),
          disabled: !this.headerData.reparse || this.isCopied,
          suOnly: true,
        },
      ];
    },
    speedDialIresultItems() {
      return [
        {
          label: "Open",
          icon: "iresult i-open",
          command: () => this.setIResultToolbarAction("open"),
        },
        {
          label: "Undo",
          icon: "iresult i-undo",
          command: () => this.setIResultToolbarAction("undo"),
        },
        {
          label: "Delete",
          icon: "iresult i-delete",
          command: () => this.setIResultToolbarAction("delete"),
        },
        {
          label: "Rename",
          icon: "iresult i-rename",
          command: () => this.setIResultToolbarAction("rename"),
        },
        {
          label: "Save As",
          icon: "iresult i-save-as",
          command: () => this.setIResultToolbarAction("saveAs"),
        },
        {
          label: "Save",
          icon: "iresult i-save",
          command: () => this.setIResultToolbarAction("save"),
        },
        {
          label: "New",
          icon: "iresult i-new",
          command: () => this.setIResultToolbarAction("new"),
        },
      ];
    },
    isCopied() {
      return this.headerData?.pollid?.includes("copied");
    },
    logoClass() {
      const value = this.headerData.engine || this.headerData.device;
      if (!value) {
        return;
      }

      return ["logo", "logo-" + value, "logo-" + this.zoomSelection];
    },
    logoSrc() {
      if (this.headerData.engine === 'google' && this.headerData.device === 'pc') {
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAACsElEQVR42mNgIAHcdzAQeO5goPDa1VDqvwMDCwM1AMigV07Goa8cTVa/dDJ5+crZ9D8MA/m/XjubnAXSLS9sdJXIsuClk1HgS2eTe8gG48G/gWpnvgf6lCjDzxgbs4I0EGn4fzTf3X/rbKiFP3gYGJiBwbOOHAsglphefemsI04giIx7cBnwwsn4AlC++5WjcR7QIRVAVy8HGvqJNAscTaxfOZv8xbDAyeT2C0dDZ2x6HtroCr52NJlBlAUg8LlD/PArN2M07xuf/gA0iGAqtLTkJGjBr10MBr/3sP7/voTv/5sgPVgkvnvjpC/NQC3wezdLA8gSEP65meP/uzQ1kC/KGagJfu1h2QGzBIx3s/790iqGNYztmr4aOrV+Xk8Mdmj+2oDkE9abyJb82s16F5eDHFu/uDu1fvlPJL6KsGQPyxMUn+xhOU0VS1q+PKG5TxxbvtzHGSc/gXEye6Mi1jixb/pk69j6+TY2DDT4L5pFJ7Gmrte7uP9nrLX9b7w8kKTUZV3/VQroy39oPlmAkU/Obxf977XK87/JiuD/xiuC3ukv9CE6nzg1f25BDy775o+JKIoWbVY/bLkiEGwBDBuvCD6tu9SbYI4HBaFT69cfqL74/M2y/qMQikKTFUHWQEP/IlsCtei20bJAJ6ym73dgMV2cmuXQ8fQLui8cmj9PwarHeHlQD7olcLw86DxQvhsYV3lGy4LKjJYHzzVeHvwMJGe6OOW/Xc8FZEteONR/EsHu71WhzMC4WI/TInx4edh/68nrgRZ8/mHf+NkRb/gazzRmBbp2FlkWrQj6aDFtsifRSdJoeVAgMHjuEmM40Pf/gGq3G68IUCa95ARGrPGKwFCj5YFrgIa8RDP8D9Dwa8D4mWywOMiUaqW1wfwAAYPlAQr6q3ykteq12IjVBwDAMv42sa57lQAAAABJRU5ErkJggg==";
      } else if (this.headerData.engine === 'bing' || this.headerData.device === 'bingpc') {
        return "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3e%3cpath fill='%23f3f3f3' d='M0 0h23v23H0z'/%3e%3cpath fill='%23f35325' d='M1 1h10v10H1z'/%3e%3cpath fill='%2381bc06' d='M12 1h10v10H12z'/%3e%3cpath fill='%2305a6f0' d='M1 12h10v10H1z'/%3e%3cpath fill='%23ffba08' d='M12 12h10v10H12z'/%3e%3c/svg%3e"
      } else if (this.headerData.engine === 'baidu') {
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAIAAABLixI0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAASiSURBVEhLfZV7TFtVHMf7lyayIY/xaEvLo6UUmGODoRsbaDDqnLqni8hmJkjoHDKd4l+KrxAjM+rYhtFMM6cTMwgzI8HMxelCXFzUZSEKGug99/ZSoE9KH7SUtb1+T89dQdP4y+ePm9+953vO+b2uQkpmf//uLK8Wyzdavh0KyC7Zln74zjk8MHMruCg7VlgSLX8g9uATM4VlvKFCSFWSmMcD4m9CLU0kp0jI0Qk1tZwU88Wdy5ZE6+KgM0NDSistQF3Mv/muB8B/8pN5lZ4vWS8YNwjZheTc+QX2fcKSaA2cnlTqBaaVX8q3vmAH8FdsEvX3yH5tqfB8K8GV48iWRGto0JFVwMtrjPyLrzoB/HdmmJnTuMGiKeHfentWkqJxZINW1OVY+vkisQiLAK6JiUVjBUGwcJdVueT6ryEAf9UWUb+Ongtaa7Tc+J/BuMKyKbzu0KY6IVUtaA1mMNA/J0mx97odd6SZ0/N4UzMnfyhJXcfmUpVcQRmfqSW1D02vvB0zhandoTHwOAIOAnAR9kIQI16rmz3ftmDPCdeTB2bPnsGVI+Zxb+cbNtB21Dk6ijxEFRX3WZAaFgig1JFPP/fKa//HYj6N0YK0gjwDryoyc5NBRSI1jLxi/tiHrJqYhaVoQGaFnR8MZOfLdQMKSoWDrXbF/dusxfGIMjI15NJlP1sgjjn3NVq3P0bAw9tIfx8qI8JefdDjwa6JVQgRmkRx5pw3p5Do1graEh6UVomSRI8w8dusWk+0RqForUyamjSbUAe38Pajk//SQpTurRNpfXV2uatrxUd2TIMJc5juK0mVm4V4OAj2BPHNaRv0vi/g7YmP55VFy3fEUR7fO52kVmHtrzhxWVO7fWg4wA7F5JBotUFg5bZGS3AhgDJAz/X1+5JoPdNiw/440Zdf0+7VGHmQyDWCW1Qu/Hh1weuLfNXnA0c6HJev0Lr9r1bTITsKMk3FKVImERR4cnU8SFdzKh29FOZHSrY5I49LRCNhspbf7t1abwF3ZXGdr6Oml626bgrgYfj7ACSaTLTP2zscGXnkyk8LgGUDJmu1dzgxRsCje2jH3hwN73xqpqeXnquyRgShUGzggh9a+5ttcB4+alfrecM6ys0bcg1RrdFfHCk5RFUsgP7TdJLU1JF0NWk5TJcltPov+HDT/U1xrZfsGEeF5QJYvxllRI1qvfaOG7lAgsDZXqT81o5dmKjcsya6rGqrCBYXY98M+NDwexpwcFmLZQOz18XToUS1uo/P48BoJlD9AIIVdnmkz77wDl+iRcsW+P1R11z0+CkP+wO0vexIaKVreNu4Vdayc64MLa0ggCG3c5/1xojN5485XZFgMMoaePvuaQdxz/tiLnfE6402HbLhdigUgCwvhelElGPf2ICfAg9QjfgIc/VuJbc6l1uVw6FXgFLHr1YSOBH+TA2Hb/BldiHlSJuFicha6MGDz82AsiqLSkcrs6beums32dsw1dU9B1ra7A0HprbUW4orBNR6Vj4p3yg2Ps0D1r+whJZsk+ala9dDY3+FXe6oFEM1L49zPLtdS3+MhUeuBa+OBDmCssJMv/2jlKR/AP9xD9/7wqzfAAAAAElFTkSuQmCC"
      } else if (this.headerData.engine === 'so' ) {
        return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAADlElEQVR42t1WXWgUVxS+bSOavzU7v5tEDbYGU2LzEkJpQSoIDWZj4s7s4kPRBx8KvgXtQ0WKID60tPQXrIFKoKI1S5LdnZk7MyuFoC1t00ZFEbGtD6GpLQ01dpPdmcnv7blpdjKzSYzZBx86cNjlnnO+73znnDu7CP2vn/JUhyhg+VjIjN4Cy4TM2FwoHZ1fMDNKv/8Dn0OCKb3DKu0N62f4ei8rYulD0ZRHKGh1OkZWMyByBFO+yuvSQch85onwq3BbnWhEb64ImFeyCjGvySfR4J6SxxKU9bdWC7r0XSFICBQJZiwBFZ+FAj4S0/IXoPIbUDFZoMrioH0A9ezKDPq+gGBI170ENAl6fpqqQ0pzmduOOHouEG9lhHTnq4Ihf1ug1mZN6ehygsFYhahHThYQ/MljWV6zvYkDVaCwpyB3hNUiL/oCYWhR7xwgaGZhkGQ12f6ncmAvC4oNj5oZUYf5eB8gUEPp2Gx+uLSydW/k+Z2VMLtxT6HDPj8c3PcMOcMqUksxd0swo597SKYqBl4XXGe1GX3krYBLdtQURWJIh6rN2FQeCzY14lWSyztEQ76y+WI4WAwJrG8Y2p1xsUypa4nEWNp3SkI3phgS3pDb6CvIVYIPHHOdtUrkwY7+/aT5Qitp7Av/VAeXshiS59WON5r62pxdvWFSiyVCt9Z1Rr96bej85UYymqohPwxsn0gkXmguhkRJ1J+9l9xKbgxsI4fju2dL4+21rnMose29MS007+gCAZvPqdy59RIMv4k25DD3B8WwMU+uJrf/6gv4G7MvT2r87UUS4mB+JqeyEXLqyS4j0ZmArTK9i7lg3Oy4wr/rDxpEJZbKnnB0Pq+G2Br3u6MFw2sRjMVRhaOynwDw3H8EPLFVbjSbCjYtCx6Po822xt7xEWE+a6nccZIsqyE9aJO/ehSYSpa/ZGMOuzmUROOmLSX4No3JxlHIUQP1mThi3ES7f1MdKPjRS0TNwtwvYL22xrxvY/aMpQY/szT+CsQ98sYBiQMkH9DOZGkBGnuXnmU17ppP0UQf22Br/M++ZNcoed6W+Qi07WNQuJHiWCmmC1o4v3AO8ctal72EREdnPwVVoysD+qqftnXu+5zGHOnuRhvyGDkl0GJh9jfAmLNU/vaqK+kYlTsthXkL+j5MwbzgAPwQWtY/gas6/0qVi4hu4pdN5ahnz8LsCPzAZfo21lsq84qll255Kv9+/gVlxpWSX8DbrwAAAABJRU5ErkJggg=="
      } else {
        return '';
      }
    },
  },
  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == ";") {
        this.refreshOverride = !this.refreshOverride;
      }
      if (e.shiftKey && e.key == ":") {
        this.hotKeyPressed = !this.hotKeyPressed;
        this.cardTypeModel;
        this.reparseToggle();
      }
    });
  },
  mounted() {
    this.indicateCopied = this.isCopied;
    window.setTimeout(() => (this.indicateCopied = false), 5000);
  },

  methods: {
    ...mapActions("timeline", ["toggleHideCardById"]),
    ...mapActions("routeParams", ["setCtid", "setResultset", "setStart", "setEnd"]),
    ...mapActions("iresults", ["setIResultToolbarAction"]),

    displayDate(value) {
      const fString = {
        "regular-view": LONG_DATE_FORMAT,
        "medium-view": MEDIUM_DATE_FORMAT,
        "small-view": SHORT_DATE_FORMAT,
      }[this.zoomSelection];

      return this.$dateTime.fromISO(value).toUTC().toFormat(fString);
    },
    dateTime(date) {
      const dt = this.$dateTime.fromISO(date).toUTC().startOf("day");
      return dt.toSeconds();
    },
    setActionGetHistory(tid) {
      this.$api.getCtid(this.client.clientid, tid).then((result) => {
        // set last 30 days
        const now = this.$dateTime.now();
        const startDate = now.minus({ days: 30 }).toFormat(IMPACT_QUERY_DATE_FORMAT);
        const endDate = now.toFormat(IMPACT_QUERY_DATE_FORMAT);
        this.setStart(startDate);
        this.setEnd(endDate);

        this.setResultset("history");
        this.setCtid(result.data);
      });
    },
    initReparse(googlepollid, clientid) {
      if (!this.parsing) {
        this.parsing = true;
        this.$api
          .reparse(googlepollid, clientid)
          .then((res) => {
            if (res.data) {
              this.parsing = false;
              this.$parent.$emit("refreshCard", res.data);
            }
          })
          .catch((e) => {
            this.parsing = false;
            this.$notifier.error({ message: "Reparse failed", e });
          });
      }
    },
    reparseToggle() {
      const item = _.find(this.speedDialItems, (sdItem) => sdItem.label === "reparse");

      if (item) {
        item.disabled = !this.headerData.reparse && !this.refreshOverride;
      }
    },
    liveSearchHandler() {
      if (this.isLiveSearchAvailable) {
        const h = this.headerData;
        this.openSearch(h.keyword, h.location, h.country, h.lang);
      }
    },
    pageIconClickHandler() {
      if (this.isPageIconClickable) {
        this.$emit("pageIconSelect", this.htmlLink);
      }
    },
    pickMenuItem(item) {
      this.$refs.menu.toggle();
      item.command && item.command();
    },
  },
  setup() {
    const { openSearch } = useLiveSearchHelper();
    return {
      openSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.clickable {
  @include hover-clickable-effect;
}

.clickable-text {
  @include hover-clickable-text-effect;
}

.small-view .card {
  --timeline-small-view-header-box-margin: 2px;

  overflow: hidden;
  font-size: var(--timeline-card-header-font-size-small);
  margin: var(--timeline-small-view-header-box-margin);
  width: calc(var(--timeline-card-width-small) + (var(--timeline-result-margin-small) * 2) - (var(--timeline-small-view-header-box-margin) * 2));
  height: 2.75rem;
  color: var(--fb-blue-70);
  text-align: center;
  border: none;

  &.history {
    font-weight: bold;
    height: 1.5rem;
  }
}

.title-date {
  color: var(--fb-blue-70);
  white-space: nowrap; // for image copies - DM-3876
}

.regular-view {
  .card {
    margin: var(--timeline-result-margin-regular);
    font-size: var(--timeline-card-header-font-size-regular);
    padding: 0.5rem;
  }

  .term-field {
    height: 36px;
    margin-top: 0.5rem;

    .logo-regular-view {
    width: 1.125rem;
    margin-left: .3rem;
    }
  }

  .title-location {
    font-size: var(--timeline-card-header-font-size-medium);
  }

  .suggestion-wrapper :deep(ul) li {
    font-size: var(--timeline-card-header-font-size-regular);
  }
}

.medium-view {
  .card {
    margin: var(--timeline-result-margin-medium);
    font-size: var(--timeline-card-header-font-size-medium);
    padding: 0.2rem;
  }

  .term-field {
    height: 32px;
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    line-height: 14px;

    .logo-medium-view {
        width: .9rem;
        margin-left: .3rem;    
    }
  }

  .title-location {
    font-size: var(--timeline-card-header-location-font-size-medium);

    &.ellipsis {
      @include ellipsis;
      max-width: var(--result-text-width-card-title-medium);
    }
  }

  .suggestion-wrapper :deep(ul) li {
    font-size: var(--timeline-card-header-font-size-medium);
  }
}

.regular-view,
.medium-view {
  .move-handle {
    color: var(--fb-blue-70);
    cursor: move;
  }

  .card {
    background: #ffffff;
    border-radius: var(--border-radius-card);
    margin-bottom: 0;
    border: 0;
    transition: box-shadow 1s ease-in-out;

    &.indicate-copied {
      box-shadow: 0 0 10px var(--fb-blue-70);
    }

    .card-body {
      border-radius: var(--border-radius-card);
      padding: 0 3px;

      .title-location {
        font-weight: 500;
        line-height: 14px;
        color: var(--text-color-secondary);
      }

      i,
      svg {
        &.document-icon {
          color: var(--timeline-toolbar-selected-color);
          position: relative;
        }

        &.fa-mobile-alt {
          top: 4px;
        }
      }

      .reparse {
        position: absolute;
        right: 14px;
        top: 4px;
        color: var(--timeline-toolbar-selected-color);
        font-size: 16px;
        margin: auto 0;

        i {
          opacity: 0.8;
        }
        .reparse-spin {
          color: var(--timeline-toolbar-selected-color);
          animation: spin 2s linear infinite;
        }
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }

      .header-data {
        height: 30px;
      }

      .dropdown {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: var(--fb-gray-70);
      }

      .dropdown {
        .dropdown-toggle {
          cursor: pointer;
        }

        .dropdown-menu.show {
          max-height: 20rem;
          overflow: auto;
          cursor: pointer;
        }

        li {
          font-size: 0.75rem;
        }
      }

      .term-field {
        border: 1px solid #dce3ef;
        box-sizing: border-box;
        border-radius: 4px;
        overflow: hidden;

        .term-field-title {
          text-transform: capitalize;
          display: inline-block;
          vertical-align: middle;
          max-height: 32px;
          cursor: pointer;
          color: var(--fb-gray-70);
          margin-left: 2px;

          &.unset {
            text-transform: unset;
          }
        }

        .icon-link {
          margin-left: auto;
          text-decoration: none;
          display: flex;
          align-items: center;
          padding: 10px;
          color: var(--icon-link-color);
        }

        img {
          margin-right: 5px;
        }
      }

      .suggestion-wrapper {
        min-width: 200px;
        padding-left: 0.5rem;

        :deep(ul) {
          list-style-type: none;
          padding-left: 0;
          margin-bottom: 0;
          display: block;

          li {
            display: list-item;
            color: var(--fb-gray-70);
          }
        }
      }
    }

    .second-page-title {
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: var(--timeline-toolbar-selected-color);
      text-align: center;
      padding: 7px;
    }
  }
}

.card-header-dropdown {
  .custom-dropdown-toggle {
    background: white;
    color: var(--fb-gray-70);
    padding: 0px 0px 0 6px;
    border: 0;

    &:after {
      display: none;
    }

    &:focus,
    &:focus-visible,
    &:hover,
    &:active,
    &.active {
      background-color: transparent !important;
      border-color: transparent !important;
      color: var(--fb-gray-70) !important;
      outline: 0;
    }
  }

  .dropdown-menu {
    --bs-dropdown-link-active-bg: transparent !important;
  }

  .dropdown-menu {
    .dropdown-item {
      padding: 0.5rem 1rem;
      font-size: 0.95rem;
      color: var(--fb-gray-70);
    }
  }
}
</style>
