<template>
  <carousel-wrapper
    v-slot="slotProps"
    :element-key="elementKey"
    :element-data="elementData"
    :display-name="displayname"
    icon-name="icons latest-posts">
    <div
      v-for="(postItem, index) in elementData.post"
      :key="slotProps.accordionKey + index"
      class="accordion-item"
    >
      <highlight-result :element-data="postItem" :class="getMarkupClasses(postItem)">
        <div
          class="accordion-header markup-container markup-container-with-image"
          :id="'heading' + slotProps.accordionKey + index"
        >
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="postItem.desc ? postItem.desc : getSocialMediaLogo(postItem)" />
            </div>
          </div>
          <div>
            <span class="card-title post-title ellipsis" v-html="this.alterTitle(postItem.title)" />
            <markup-link
              :resultData="postItem"
              :datetime="elementData.datetime"
              :resultType="postItem.resulttype"
            />
          </div>
        </div>
      </highlight-result>
    </div>
  </carousel-wrapper>
</template>

<script>
import CarouselWrapper from "@/components/card/CarouselWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import commonUtil from "@/utils/commonUtil";
import { socialMediaLogos } from "@/carousel-logos.js";

export default {
  name: "LastestPostsBox",
  props: {
    elementKey: String,
    elementData: Object,
    headerData: Object,
  },
  components: {
    CarouselWrapper,
    MarkupLink,
    HighlightResult,
  },
  data() {
    return {
      // to be added soon
    //  defaultImage: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1200 630'%3E%3C!-- Background --%3E%3Crect width='1200' height='630' fill='%23f5f5f5'/%3E%3C!-- Decorative elements --%3E%3Cpath d='M0 0 L1200 0 L1200 30 Q600 60 0 30 Z' fill='%23e0e0e0'/%3E%3Cpath d='M0 630 L1200 630 L1200 600 Q600 570 0 600 Z' fill='%23e0e0e0'/%3E%3C!-- Image placeholder icon --%3E%3Crect x='500' y='215' width='200' height='150' rx='10' fill='none' stroke='%23909090' stroke-width='8'/%3E%3Cpath d='M520 335 L570 285 L620 335' fill='none' stroke='%23909090' stroke-width='8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='650' cy='255' r='15' fill='%23909090'/%3E%3C!-- Text placeholder lines --%3E%3Crect x='400' y='400' width='400' height='20' rx='5' fill='%23d0d0d0'/%3E%3Crect x='450' y='440' width='300' height='20' rx='5' fill='%23d0d0d0'/%3E%3C/svg%3E"
    };
  },
  computed: {
    displayname() {
      const term = this.headerData.keyword;
      return "Latest Posts From " + term;
    }
  }, methods: {
    getSocialMediaLogo(postItem) {
      const domain = commonUtil.extractDomain(postItem.url);
      const matchingKey = Object.keys(socialMediaLogos).find(key => domain.includes(key));
      return matchingKey ? socialMediaLogos[matchingKey] : null;
    },
    alterTitle(title) {
      if (title.includes("~")) {
        return title.replace("~", " - ");
      }
      return title;
    },
  }
};
</script>

<scss lang="scss" scoped>
  .outer-image-wrapper {
    overflow: hidden;
  }
 .image-wrapper {
   width: 68px;
   height: var(--timeline-result-height-regular);
   margin: auto;
   display: flex;
   justify-content: center;
 }
</scss>
